export const environment = {
  production: false,
  api: {
    url: 'https://vc-core-api.nt-test.dev/api'
  },
  website: 'https://www.google.com',
  featuresFlag: {
    customer: true,
    exchange: true,
    voucher: true,
    bankAccounts: false,
    banks: false,
    kyc: false,
  }
};
